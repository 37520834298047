.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    transition: .3s;
}

.nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.nav__link {
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
}

.nav__link:hover {
    color: hsl(203, 89%, 50%)
}

.copyright {
    color: hsl(245, 15%, 65%);
    font-size: var(--small-font-size);
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
}


.nav__toggle {
    position: fixed;
    top: 0.18rem; /* Adjust the top value to move the button up */
    left: 1.75rem;
    cursor: pointer;
    height: 40px;
    width: 45px;
    background-color: var(--body-color);
    border: 1px solid #e8dfec;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: .3s;
}

@media screen and (max-width: 1024px) {
    .sidebar {
      left: -110px;
      z-index: -1; /* Adjusted z-index to place the sidebar behind other content */
    }
}

@media screen and (max-width: 1024px) {
    .aside {
        left: -110px;
    }

    .home__social-link {
        font-size: 1.125rem;
    }

    .nav__toggle{
        display: flex;
    }

    .nav__toggle-open {
        left:140px;
    }

    .show-menu {
        left: 0;
    }
}