.work__filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 1.875rem;
    margin-bottom: 2.5rem;
  }
  
  .work__item {
    cursor: pointer;
    font-weight: var(--font-bold);
    transition: .3s;
  }
  
  .work__item:hover {
    color: var(--first-color);
  }
  
  .work__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
  }
  
  .work__card {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
  }
  
  .work__thumbnail {
    position: relative;
    height: 237px;
  }
  
  .work__img {
    transition: transform 0.3s;
  }
  
  .work__card:hover .work__mask {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #6c6ce5;
    opacity: 0.8; 
    transition: opacity 0.3s;
    pointer-events: none;
  }
  
  .work__category {
    color: #fff;
    background-color: var(--first-color);
    border-bottom-left-radius: 0.9rem;
    border-bottom-right-radius: 0.98rem;
    position: absolute;
    top: 0;
    left: 1.5rem;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 0.19rem 0.625rem;
    transform: translateY(-40px);
    transition: .3s;
  }

  .halloffame {
    cursor: pointer;
    font-weight: var(--font-bold);
    transition: .3s;
  }

  .hall-of-fame-grid .work__card {
    width: 160px; /* Adjust the width to match the image width */
    height: 120px;
  }
  
  .halloffame:hover {
    color: var(--first-color);
  }

  .hall-of-fame-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }

  .hall-of-fame-grid .work__img {
    width: auto; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  .work__title {
    color: #fff;
    font-size: var(--h3-font-size);
    margin: 0 0 0.98rem;
    padding: 0 1.25rem;
    position: absolute;
    top: 3.75rem;
    transform: translateY(20px);
    transition: .3s;
    opacity: 0;
  }
  
  .work__button {
    color: #fff;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    font-size: var(--h3-font-size);
    display: block;
    background-color: #ffd14c;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    transition: .3s;
    opacity: 0;
  }
  
  .work__card:hover .work__button {
    opacity: 1;
  }
  
  .work__card:hover .work__title,
  .work__card:hover .work__category {
    opacity: 1;
    transform: translateY(0);
  }
  
  .work__card:hover .work__thumbnail {
    overflow: hidden;
  }
  
  .work__img:hover {
    transform: scale(1.2);
  }
  

  .lightbox-overlay {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lightbox-content {
    position: relative;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    overflow: hidden;
  }
  
  .lightbox-img {
    max-width: 100%;
    max-height: 100vh;
  }
  
  .lightbox-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    .work__container {
        grid-template-columns: repeat(2, 330px);
        justify-content: center;
      row-gap: 1.875rem;
    }
}

@media screen and (max-width: 768px) {
    .work__container {
        grid-template-columns: 310px;

    }

    .work__filters {
      row-gap: .25rem;
    }
    
    
}

