.testimonials__container {
    max-width: 700px;
    margin: auto;
}

.testimonial_item {
    text-align: center;
    padding: 0 1.25rem 1.25rem;
}

.thumb {
    height: 5.6rem;
    width: 5.6rem;
    margin: 0 auto;
}

.testimonial_title {
    margin-top: 0.75rem;
}

.subtitle {
    color: #8b88b1;
    font-size: var(--small-font-size);
}

.comment {
    background-color: var(--container-color);
    padding: 1.875rem;
    margin-top: 1.5rem;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    position: relative;

}

.comment::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid var(--container-color);
    position: absolute;
    left: 49.5%;
    top: -0.625rem;
    transform: translateX(-7.5px);
}