.services__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.services__card {
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: var(--container-color);
    transition: all 0.3s ease-in-out;
}

.services__card:hover {
transform: translateX(-10px);
}

.services__card:nth-child(1) {
    background-color: rgb(14, 237, 203);
    box-shadow: 0px 5px 20px 0px rgb(14, 237, 203 / 50%);
}

.services__card:nth-child(2) {
    background-color: rgb(235, 246, 20);
    box-shadow: rgb(235, 246, 20 / 50%);
}

.services__card:nth-child(3) {
    background-color: rgb(19, 209, 234);
    box-shadow: rgb(19, 209, 234 / 50%);
}

.services__img {
    margin-bottom: 1.25rem;
    width: 120px; 
    height: 120px; 
    object-fit: cover; 
}

.services__title {
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: #fff;
}

.services_description {
    color: #f8f9fa;
}

.services__card:nth-child(2) .services__title {
    color: var(--title-color);
}

.services__card:nth-child(2) .services__desciption {
    color: var(--text-color);
}

@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: repeat(2, 330px);
        justify-content: center;
        row-gap: 1.875rem;
    }
}

@media screen and (max-width: 768px) {
    .services__container {
        grid-template-columns: 310px;

    }
}

    @media screen and (max-width: 350px) {
        .services__container {
            grid-template-columns: 1fr;
    
        }

    
}

